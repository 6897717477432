import axios from "axios";
import React, { useEffect, useState } from "react";

const SetPrice = () => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const [getPrice, setGetPrice] = useState({
    priceRate: "",
    inDhaka: "",
    outDhaka: "",
  });

  const getSetPrice = async () => {
    setButtonLoading(true);
    try {
      const { data } = await axios.get(`https://api.shopinshop.com.bd/get/price`);
      setGetPrice(data);
      setButtonLoading(false);
    } catch (err) {
      console.log("get set price", err?.message);
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    getSetPrice();
  }, []);

  const onsubmitSetPrice = async (e) => {
    setButtonLoading(true);
    e.preventDefault();
    const priceRate = e.target.price.value.trim();
    const inDhaka = e.target.inDhaka.value.trim();
    const outDhaka = e.target.outDhaka.value.trim();

    const submitBody = {
      priceRate: parseFloat(+priceRate),
      inDhaka: +inDhaka,
      outDhaka: +outDhaka,
    };

    try {
      const { data } = await axios.patch(
        "https://api.shopinshop.com.bd/set/price",
        submitBody
      );
      setButtonLoading(false);
    } catch (err) {
      console.log("set Price error", err?.message);
      setButtonLoading(false);
    }
  };
  return (
    <div className="flex justify-center">
      <form onSubmit={onsubmitSetPrice} className="w-[80%] mx-auto py-4">
        <div className="p-4">
          <h2 className="font-bold text-xl text-black">
            Set Product Price Rate
          </h2>
          <div className="mt-4 ">
            <h2 className="mb-2">How much is 1 rupee?</h2>
            <input
              type="text"
              name="price"
              value={getPrice?.priceRate}
              onChange={(e) =>
                setGetPrice({ ...getPrice, priceRate: e.target.value })
              }
              className="outline-none border-black rounded-md border px-4 py-1 "
              required
            ></input>
          </div>
        </div>
        <div className="p-4">
          <h2 className="font-bold text-xl text-black">Set Delivert Cost</h2>
          <div className="mt-4 ">
            <h2 className="mb-2">Delivery Charge in Dhaka</h2>
            <input
              type="number"
              name="inDhaka"
              value={getPrice?.inDhaka}
              onChange={(e) =>
                setGetPrice({ ...getPrice, inDhaka: e.target.value })
              }
              className="outline-none border-black rounded-md border px-4 py-1 "
              required
            ></input>
          </div>
          <div className=" mt-4 ">
            <h2 className="mb-2">Delivery Charge out of Dhaka</h2>
            <input
              type="number"
              name="outDhaka"
              value={getPrice?.outDhaka}
              onChange={(e) =>
                setGetPrice({ ...getPrice, outDhaka: e.target.value })
              }
              className="outline-none border-black rounded-md border px-4 py-1 "
              required
            ></input>
          </div>
        </div>

        <div className="ml-16">
          {buttonLoading ? (
            <span
              type="submit"
              className="bg-black px-4 opacity-50 py-2 rounded-md text-white"
            >
              Please wait....
            </span>
          ) : (
            <button
              type="submit"
              className="bg-black px-4 py-2 rounded-md text-white"
            >
              Update
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default SetPrice;

import React, { useState } from 'react';
import { toast } from 'react-toastify';

const SliderUpdate = ({ sliderModal, setSliderMoodal }) => {
    const [checks, setChecks] = useState(sliderModal.textBox);
    const [lisnks, setLinks] = useState(sliderModal.link);
    const [sliderDescription, setSliderDescription] = useState(sliderModal.description);
    const [sliderTitle, setSliderTitle] = useState(sliderModal.title);
    // console.log(checks);
    // console.log(typeof (checks))

    const handelSliderUpdate = (event) => {
        event.preventDefault();
        const title = sliderTitle;
        const description = sliderDescription;
        const link = lisnks;
        const textBox = checks;

        const updateInfo = {
            title, description, link, textBox
        }

        fetch(`https://api.shopinshop.com.bd/slider/update/${sliderModal._id}`, {
            method: "PUT",
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(updateInfo)
        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged) {
                    toast("Slider Update Successfully");
                    setSliderMoodal(null);
                }
            });

    }
    return (
        <div>
            <input type="checkbox" id="slider-update" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-center text-orange-500">Update Slider</h3>

                    <div>
                        <form onSubmit={handelSliderUpdate}>
                            <div>

                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-primary font-semibold capitalize">Slider title </span>
                                    </label>
                                    <input type="text" onChange={(e) => setSliderTitle(e.target.value)} name='title' defaultValue={sliderTitle} className="input input-warning w-full" />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text text-primary font-semibold">Slider Description</span>
                                    </label>
                                    <textarea onChange={(e) => setSliderDescription(e.target.value)} className="textarea textarea-warning h-24" name='description' defaultValue={sliderDescription} ></textarea>
                                </div>


                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-primary font-semibold">Slider Link </span>
                                    </label>
                                    <input onChange={(e) => setLinks(e.target.value)} type="text" name='link' defaultValue={lisnks} className="input input-warning w-full" />
                                </div>

                            </div>

                            <div className="form-control">
                                <label className="label  cursor-pointer">
                                    <span className="label-text text-primary font-semibold">Text Hide/Show</span>
                                    <input onChange={(e) => setChecks(e.target.checked)} type="checkbox" name='check' checked={checks} className="toggle toggle-primary" />
                                </label>
                            </div>


                            <div className='flex justify-end'>

                                <div className="modal-action mr-4">
                                    <button type="submit" className="btn bg-orange-500">Update</button>
                                </div>

                                <div className="modal-action">
                                    <button onClick={() => setSliderMoodal(null)} type='reset' className="btn">Cancel</button>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SliderUpdate;
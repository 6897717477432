import React, { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";

const ProductPromo = ({ promo, setPromo }) => {
    const [promodata, setPromodata] = useState([]);

    // const promodata = [
    //     { value: 'https://i.ibb.co/Fqv6q2R/return.jpg', label: '7 Days Return' },
    //     { value: 'https://i.ibb.co/gZV9ZKs/ezgif-com-gif-maker.jpg', label: '10% Discount' },
    //     { value: 'https://i.ibb.co/zxRWf5c/made-in-india.jpg', label: '100% Indian Product' },
    //     { value: 'https://i.ibb.co/J2FtGRq/veg.jpg', label: '100% Veg' },
    //     { value: 'https://i.ibb.co/C6vpvpp/cruelty-free.jpg', label: 'Cruelty Free' },
    //     { value: 'https://i.ibb.co/6tJz8Sg/GMO-Free.png', label: 'GMO Free' },
    //     { value: 'https://i.ibb.co/kX8DcXB/handmade.png', label: 'Hand Made' },
    //     { value: 'https://i.ibb.co/HGkFB6N/herbal.jpg', label: 'Herbal' },
    //     { value: 'https://i.ibb.co/JnHTqk6/made-in-bangladesh.jpg', label: 'Made in Bangladesh' },
    //     { value: 'https://i.ibb.co/R6pp6Lh/made-in-china.jpg', label: 'Made in China' },
    //     { value: 'https://i.ibb.co/zxRWf5c/made-in-india.jpg', label: 'Made in India' },
    //     { value: 'https://i.ibb.co/3mrTffZ/made-in-japan.jpg', label: 'Made in Japan' },
    //     { value: 'https://i.ibb.co/Wx2zytn/usa.jpg', label: 'Made in USA' },
    //     { value: 'https://i.ibb.co/2qb04XB/soy-free.png', label: 'Soy Free' },
    //     { value: 'https://i.ibb.co/whpGhyZ/100-authntice.png', label: '100% Authentic' },
    //     { value: 'https://i.ibb.co/6ybF4Tj/FSSAI.png', label: 'FSSAI Approved' },
    //     { value: 'https://i.ibb.co/ByxH1fz/lactose-free.png', label: 'Lactose Free' },
    //     { value: 'https://i.ibb.co/LNJLBJD/nosuger.jpg', label: 'No Added Sugar' },
    //     { value: 'https://i.ibb.co/86q57bT/no-added-preservatives.jpg', label: 'No Preservatives' },
    //     { value: 'https://i.ibb.co/3W0ksnB/Plastic-Positive.png', label: 'Plastic Positive' },
    //     { value: 'https://i.ibb.co/KVCgcn2/Daily.png', label: 'Daily Essentials' },
    //     { value: 'https://i.ibb.co/31MqTvZ/Child-Save.jpg', label: '100% Child Safe' },
    //     { value: 'https://i.ibb.co/c1FnPH4/ezgif-com-gif-maker.jpg', label: '100% Safe' },
    //     { value: 'https://i.ibb.co/dJ3HRjg/organic.jpg', label: '100% Organic' },
    //     { value: 'https://i.ibb.co/G9vWgg1/warranty.jpg', label: 'Warranty 60 Days' },
    //     { value: 'https://i.ibb.co/gFXLpYm/pure-ayurvedic.jpg', label: 'Pure Ayurvedic' },
    //     { value: 'https://i.ibb.co/s5wCgTW/istockphoto-1220429558-612x612.jpg', label: '100% Vegan' },
    //     { value: 'https://i.ibb.co/0BYmYrg/gluten-free.png', label: 'Gluten Free' },
    //     { value: 'https://i.ibb.co/bv2rQdJ/fragrance-free.png', label: 'No Artificial Fragrance' },
    //     { value: 'https://i.ibb.co/yNFmmzF/clinically-tested.jpg', label: 'Clinically Tested' },
    //     { value: 'https://i.ibb.co/LhN5SSR/product-not-sulfate.jpg', label: 'Sulphate Free' },
    //     { value: 'https://i.ibb.co/QcLNBW7/dye-free.png', label: 'Dye Free' },
    //     { value: 'https://i.ibb.co/vvcsvzV/parabaen-free.png', label: 'Paraben Free' },
    //     { value: 'https://i.ibb.co/zFLY6GR/silicone-free.jpg', label: 'Silicone Free' },
    // ]


    useEffect(() => {
        fetch('https://api.shopinshop.com.bd/cliend/get/promoData', {
            method: "GET",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                setPromodata(data);
            })
    }, [])


    return (

        <div className='my-2'>
            <h1 className='label-text font-semibold mb-2'>Product Promo <span className='text-sm text-primary'>(Make a maximum of 5 options)</span></h1>
            <MultiSelect
                className="select-primary border-primary border-[1px] rounded-lg w-full"
                options={promodata}
                value={promo}
                onChange={setPromo}
                labelledBy="Select Promo"
            />
            {
                promo.length <= 0 && <span className="label-text-alt text-warning">
                    <i className="fa-solid fa-circle-exclamation"></i> Please select promo for your product.
                </span>
            }
        </div>

    );
};

export default ProductPromo;
import React from "react";
import { Outlet } from "react-router-dom";
import CustomLink from "../Menu/CustomLink/CustomLink";

const Setting = () => {
  return (
    <div>
      <div>
        <h2 className="text-xl text-center text-orange-500 mb-4 font-bold">
          SETTING
        </h2>
        <div className="flex gap-4">
          <CustomLink className="btn btn-sm" to="/setting">
            Promos
          </CustomLink>
          <CustomLink className="btn btn-sm" to="slider">
            Sliders
          </CustomLink>
          <CustomLink className="btn btn-sm" to="setPrice">
            Set Price
          </CustomLink>
          <button disabled className="btn btn-sm" to="/setting">
            Colors
          </button>
          <button disabled className="btn btn-sm" to="/setting">
            Sizes
          </button>
          <button disabled className="btn btn-sm" to="/setting">
            Brand
          </button>
          <button disabled className="btn btn-sm" to="/setting">
            rating product control
          </button>
        </div>
      </div>

      <div className="bg-white mt-10">
        <Outlet />
      </div>
    </div>
  );
};

export default Setting;


import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import ButtonSpinner from '../../Shared/Spinner/ButtonSpinner';
import Spinner from '../../Shared/Spinner/Spinner';
import ProductCategoryEdits from './ProductCategoryEdits';

const ProductCategoryEditModal = ({ setProductModal, productModal }) => {
    const [selected, setSelected] = useState(productModal?.mainCategoryID);
    const [selectedCate, setSelectedCate] = useState(productModal?.category);
    const [selectedSub, setSelectedSub] = useState(productModal?.subCategory);
    const [buttonLoading, setButtonLoading] = useState(false);

    // console.log(selected)
    const { isLoading, data, refetch } = useQuery('category', () =>
        fetch('https://api.shopinshop.com.bd/category/all', {
            method: "GET",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        }).then(res =>
            res.json()
        )
    );

    const handleUpdateCategory = async (e) => {
        e.preventDefault();
        setButtonLoading(true);
        let formData = new FormData();
        formData.append(`mainCategory`, JSON.stringify(selected));
        formData.append(`category`, JSON.stringify(selectedCate));
        formData.append(`SubCategory`, JSON.stringify(selectedSub));

        await fetch(`https://api.shopinshop.com.bd/product/category/update/${productModal._id}`, {
            method: "PATCH",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: formData,
        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged) {
                    toast("Product Category Updates Successfully.");
                    setProductModal(null);
                    setButtonLoading(false);
                    refetch();
                }

            })
    }

    if (isLoading) {
        return <Spinner></Spinner>
    }
    return (
        <div>

            <input type="checkbox" id="category-edit-modal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-center text-orange-500">Product category Update</h3>



                    <ProductCategoryEdits setSelectedSub={setSelectedSub} selectedSub={selectedSub} selectedCate={selectedCate} setSelectedCate={setSelectedCate} setSelected={setSelected} selected={selected} data={data}></ProductCategoryEdits>

                    <div className="modal-action">

                        {
                            buttonLoading ? <ButtonSpinner></ButtonSpinner> : selectedCate.length <= 0 || selected.length <= 0 ? <span className='flex justify-center items-center text-warning font-bold'><i className="mr-2 fa-solid fa-circle-exclamation"></i> You got some error please solve it.</span> : <button onClick={handleUpdateCategory} className="btn bg-orange-500">Update</button>
                        }

                        <label onClick={() => {
                            refetch();
                            setProductModal(null)
                        }} htmlFor="category-edit-modal" className="btn">Cancel</label>
                    </div>







                </div>
            </div>
        </div>
    );
};

export default ProductCategoryEditModal;
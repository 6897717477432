import React, { useEffect, useState } from 'react';

const CustomersEmail = () => {
    const [customers, setCustomers] = useState([]);
    // console.log(customers)

    useEffect(() => {
        fetch(`https://api.shopinshop.com.bd/all/customer`)
            .then(res => res.json())
            .then(data => {
                // data.map(e => console.log(e.email))
                setCustomers(data)
            })
    }, []);
    return (
        <div>
            <h2>Hello , Here we show customers Email {customers.lenght}</h2>
        </div>
    );
};

export default CustomersEmail;
import React, { useState } from "react";
import { toast } from "react-toastify";
import ButtonSpinner from "../../Shared/Spinner/ButtonSpinner";

const ChildCategoryBannerUpload = ({
  childBanner,
  setChildBanner,
  refetch,
  categoryModalMain,
}) => {

  const [uploadLoding, setUploadLoding] = useState(false);
  const imgStore_key = "2f6c6879a39132782b251889cb5d783f";
  const handleChildCategoryBannerAdd = event => {
    event.preventDefault();
    const img = event?.target?.img?.files[0];

    if (img) {
      setUploadLoding(true);
      const formData = new FormData();
      formData.append("image", img);
      const url = `https://api.imgbb.com/1/upload?key=${imgStore_key}`;
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then(res => res.json())
        .then(result => {
          if (result.success) {
            const banner = result.data.url;
            // console.log("imgbanner", banner)

            if (banner) {
              const childArray = [];
              for (let child of childBanner.allCategory.subCategory) {
                if (
                  child.name === childBanner.childCategory.name &&
                  child.slug === childBanner.childCategory.slug
                ) {
                  childArray.push({
                    name: child.name,
                    slug: child.slug,
                    banner: banner,
                  });
                } else {
                  childArray.push({
                    name: child.name,
                    slug: child.slug,
                    banner: child.banner || '',
                  });
                }
              }

              const updateAllCategory = {
                name: childBanner.allCategory.name,
                slug: childBanner.allCategory.slug,
                status: childBanner.allCategory.status,
                img: childBanner.allCategory.img,
                banner: childBanner.allCategory.banner || '',
                subCategory: childArray,
              };

              fetch(
                `https://api.shopinshop.com.bd/category/update/all/${childBanner.allCategory.slug}?mainId=${categoryModalMain}`,
                {
                  method: "PUT",
                  headers: {
                    "content-type": "application/json",
                    authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                  body: JSON.stringify(updateAllCategory),
                }
              )
                .then(res => res.json())
                .then(data => {
                  if (data.acknowledged && data.modifiedCount > 0) {
                    toast("Child Banner Update Successfully");
                    refetch();
                    setChildBanner(null);
                  }
                });
            } else {
              toast("Your banner could not be uploaded for some reason");
            }
          } else {
            toast("Sorry! Nothing could be updated.");
            setUploadLoding(false);
          }
        });
    } else {
      toast("Sorry! Nothing could be updated.");
      setUploadLoding(false);
    }
  };
  return (
    <div>

      <input
        type="checkbox"
        id="child-banner-update"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-orange-500 text-center">
            Add And Update Banner
          </h3>


          {childBanner.childCategory.banner && (
            <div className={childBanner.childCategory.banner ? "w-full mb-6" : "w-full mb-6 hidden"}>
              <img className="w-[464px] h-[73px]" src={childBanner.childCategory.banner} alt="banner images"></img>
            </div>
          )}

          <form onSubmit={handleChildCategoryBannerAdd}>
            <div className="mt-6">
              <div className="flex justify-between gap-4">
                <div className="my-6">
                  <span className="pb-4 label-text w-full text-warning font-semibold capitalize">
                    (File Size 300KB And Width - 1920px Higth - 500px)
                  </span>
                  <label className="block">
                    <span className="sr-only">Choose File</span>
                    <input
                      type="file"
                      name="img"
                      className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                      required
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="modal-action mr-4">
                {uploadLoding ? (
                  <ButtonSpinner></ButtonSpinner>
                ) : (
                  <button stype="submit" className="btn bg-orange-500">
                    Update Banner
                  </button>
                )}
              </div>
              <div className="modal-action">
                <label
                  onClick={() => setChildBanner(null)}
                  htmlFor="child-banner-update"
                  className="btn"
                >
                  Cancel
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChildCategoryBannerUpload;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ProductImageUpdateModal = ({ productModal, setProductModal }) => {
    const { primaryImage, secondImage } = productModal;
    const navigate = useNavigate();

    // console.log(secondImage)

    const { register, formState: { errors }, handleSubmit, reset } = useForm({ mode: "onChange" });
    const [image, setImage] = useState(null);
    const [secondImages, setSecondImage] = useState(null);
    const secondImg = register('secondImg');
    const img = register('img');
    const [primaryImageSize, setPrimaryImageSize] = useState('');
    const [secondImageSize, setsecondImageSize] = useState('');
    // console.log(image)

    const onImageChange = (event) => {
        // console.log(event)
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size < 6000000) {
                if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/jpg" || event.target.files[0].type === "image/png") {
                    setImage(URL.createObjectURL(event.target.files[0]));
                    setPrimaryImageSize("");
                    // console.log(event.target.files[0].type)
                }
                else {
                    setPrimaryImageSize("Only Accepted jpeg, png, jpg");
                }
            }
            else {

                setPrimaryImageSize("Image Size Too Large. Please upload image max size 5MB and type jpeg, png, jpg");
            }
        }

    };


    const onSecondImageChange = (event) => {
        if (event.target.files) {
            const targetImages = event.target.files;

            const imageObject = Object.keys(targetImages).map((img, index) => URL.createObjectURL(event.target.files[index]));
            const imageObjectLength = imageObject.length;
            let ImageSize = 0;
            for (let i = 0; i < imageObjectLength; i++) {

                if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/jpg" || event.target.files[0].type === "image/png") {
                    ImageSize += event.target.files[i]?.size;
                }
                else {
                    return setsecondImageSize("Only Accepted jpeg, png, jpg");
                }
            }

            if (imageObjectLength > 5 || ImageSize > 6000000) {
                setsecondImageSize("Image Size Too Large. Please upload image max size 5MB and less then 6 Images and type jpeg, png, jpg");
            }
            else {
                setSecondImage(imageObject);
                setsecondImageSize("")
            }
        }
    };



    const updatemageOnSubmit = async (data) => {
        const imageData = data.img[0];
        const secondImageData = data.secondImg || [];
        // console.log(imageData);
        let formData = new FormData();
        formData.append('primaryImage', imageData);
        for (let i = 0; i < secondImageData.length; i++) {
            formData.append(`secondImage`, secondImageData[i]);
        };

        await fetch(
            `https://api.shopinshop.com.bd/product/image/update/${productModal._id}?path=${primaryImage}&sPath=${secondImage}`,
            {
                method: "PATCH",
                headers: {
                    authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                body: formData,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.acknowledged) {
                    toast("Product Image Update Successfully.");
                    // setButtonLoading(false);
                    reset();
                    // refetch();
                    setProductModal(null);
                    navigate("/products/admin/view");
                }
            })
            .catch((rejected) => {
                // console.log(rejected);
            });
    }


    return (
        <div>



            <input type="checkbox" id="image-update-modal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-center text-orange-500">Product Image Update</h3>


                    {
                        primaryImageSize || secondImageSize ? <h2 className='text-center text-orange-500'>{primaryImageSize || secondImageSize}</h2> : ''
                        // primaryImageSize ? <h2 className='text-center text-orange-500'>{primaryImageSize}</h2> : ''
                    }


                    <div>
                        <form onSubmit={handleSubmit(updatemageOnSubmit)}>
                            <div className="form-control w-full ">
                                <label className="label">
                                    <p className="label-text font-semibold">Update Primary Image <span className='text-orange-500'></span></p>
                                </label>
                                <div className='flex items-center space-x-6'>
                                    <div className="shrink-0">
                                        {
                                            image ? <img className="object-cover w-16 h-16 rounded-full"
                                                src={image} alt="Product Images" /> : <img className="object-cover w-16 h-16 rounded-full"
                                                    src={`https://api.shopinshop.com.bd/${primaryImage}`} alt="Product Images" />
                                        }

                                    </div>
                                    <label className="block">
                                        <span className="sr-only">Choose File</span>
                                        <input type="file"
                                            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                            {...register("img", {
                                                required: {
                                                    value: false,
                                                },

                                            })}
                                            onChange={(e) => { img.onChange(e); onImageChange(e) }}


                                        />

                                    </label>
                                </div>


                                <div className="form-control w-full ">
                                    <label className="label">

                                        <p className="label-text font-semibold">Update secondary Image <i title='You can select 1 to 5 images here. But this is optional.' className="fas fa-info-circle"></i> <span className='text-primary text-xs'>(You can upload 1 to 5 image)</span> </p>
                                    </label>
                                    <div className='space-x-6'>

                                        <label className="block">
                                            <span className="sr-only">Choose File</span>
                                            <input type="file"
                                                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                                {...register("secondImg", {
                                                    required: {
                                                        value: false,
                                                    },

                                                })}
                                                onChange={(e) => { secondImg.onChange(e); onSecondImageChange(e) }}
                                                multiple

                                            />

                                        </label>

                                        <div className="flex  items-center shrink-0 ml-0">
                                            {
                                                secondImages ? <div className='flex justify-around'>
                                                    {
                                                        secondImages.map((image, index) => <img key={index} className="mr-2 object-cover w-10 h-10 rounded-full"
                                                            src={image} alt="Product Images" />)
                                                    }
                                                </div> : <div className='flex justify-around'>
                                                    {
                                                        secondImage.map((image, index) => <img key={index} className="mr-2 object-cover w-10 h-10 rounded-full"
                                                            src={`https://api.shopinshop.com.bd/${image}`} alt="Product Images" />)
                                                    }
                                                </div>
                                            }

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="modal-action">
                                {
                                    secondImages || image ? <button className='btn bg-orange-500'>Update Image</button> : <button className='btn' disabled>Update Image</button>
                                }

                                <label onClick={() => setProductModal(null)} htmlFor="image-update-modal" className="btn">Cancel</label>

                            </div>
                        </form>
                    </div>



                </div>
            </div>
        </div >
    );
};

export default ProductImageUpdateModal;
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Spinner from '../../Shared/Spinner/Spinner';
import EditePromoModal from './EditePromoModal';
import PromoDelete from './PromoDelete';
import PromosModal from './PromosModal';

const AddPromos = () => {
    const [promo, setPromo] = useState(null);
    // const [promoData, setPromoData] = useState([]);
    const [search, setsearch] = useState('');
    // console.log(search)
    const [promosData, setPromosData] = useState(null);

    const { isLoading, data, refetch } = useQuery([search, 'promoData'], () =>
        fetch(`https://api.shopinshop.com.bd/admin/get/promoData?name=${search.toLocaleLowerCase()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => res.json())
    );

    // if (isLoading) {
    //     return <Spinner></Spinner>
    // }

    const poroStatusUpdate = (status, id) => {
        // console.log(status);
        fetch(`https://api.shopinshop.com.bd/admin/promo/status/update/${id}`, {
            method: "PUT",
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify({ status }),
        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged && data.modifiedCount > 0) {
                    toast("Your promo status has been updated");
                    refetch();
                }
                else {
                    toast("Sorry there was a problem");
                    refetch();
                }
                // console.log(data)
            })
    }

    // console.log(data)

    return (
        <div>
            <div>

                <div className='md:grid grid-cols-2 gap-4 justify-center  mt-5 items-center '>
                    <div className='md:flex justify-between hidden  order-1 md:order-2 gap-4 mb-8 md:mb-0'>

                        <label onClick={() => setPromo(true)} htmlFor="promo-add-modal" className='bg-green-400 btn hover:bg-green-500 h-12 w-52 text-white font-bold rounded-full '>Add Promo</label>
                    </div>

                    <div className='relative bg-white p-4 w-full order-2 md:order-1 rounded-full'>
                        <input onChange={(e) => setsearch(e.target.value)} className='outline-0 p-2 h-12 rounded-full pl-10 text-orange-500 text-lg border-2  hover:shadow-lg w-full' type="text" name="search" placeholder='Search Promo Name' />
                    </div>
                </div>

                {
                    isLoading ? <Spinner></Spinner> : <div className="overflow-x-auto mt-10">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Promo Image</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    data?.map((promoData, index) => <tr key={promoData._id}>
                                        <th>{index + 1}</th>
                                        <td className='text-center w-1/4'>
                                            <img src={promoData.value} height='60px' width='60px' alt='Promo Images' />
                                        </td>
                                        <td>{promoData.label}</td>

                                        <td>
                                            <div className="form-control">
                                                <label className="label cursor-pointer">
                                                    <input onClick={(e) => poroStatusUpdate(e.target.checked, promoData._id)} type="checkbox" className="toggle toggle-primary" checked={promoData.status} />
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex justify-between'>

                                                <label onClick={() => setPromosData(promoData)} htmlFor="edite-modal-promo"> <i className=" cursor-pointer fa-solid fa-pen-to-square"></i></label>
                                                <label onClick={() => setPromosData(promoData)} htmlFor="promo-delete-modal"> <i className="cursor-pointer fa-solid fa-trash-can"></i></label>

                                            </div>
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                }
                {
                    promo && <PromosModal promo={promo} refetch={refetch} setPromo={setPromo}></PromosModal>
                }
                {
                    promosData && <PromoDelete refetch={refetch} promosData={promosData} setPromosData={setPromosData}></PromoDelete>
                }
                {
                    promosData && <EditePromoModal refetch={refetch} promosData={promosData} setPromosData={setPromosData}></EditePromoModal>
                }
            </div>
        </div>
    );
};

export default AddPromos;
import React, { useState } from "react";
import { toast } from "react-toastify";
import ButtonSpinner from "../../Shared/Spinner/ButtonSpinner";
import LongDescription from "./LongDescription";
import ProductPromoUpdate from "./ProductPromoUpdate";

const ProductEditModal = ({ productModal, setProductModal }) => {
  const {
    productName,
    sPrice,
    productPromo,
    sku,
    logDescription,
    shortDescription,
    brand,
    orderType,
    price,
    quantity,
    video,
    deliveryInDhaka,
    outDhaka,
    color,
    size,
  } = productModal;

  const [shoptDUpdate, setShortDUpdate] = useState(shortDescription);
  const [longD, setLongD] = useState(logDescription);
  const [pName, setPname] = useState(productName);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [Sku, setSku] = useState(sku);
  const [promoSelected, setPromoSelected] = useState(productPromo);
  const handelProductUpdate = (event) => {
    event.preventDefault();
    setUpdateLoading(true);
    const productNames = pName;
    const orderTypes = event.target.orderType.value || orderType;
    const prices =
      +event.target.price.value + +event.target.price.value * 0.1 || price;
    let sP = event.target.sPrice.value;
    const quantitys = event.target.quantity.value || quantity;
    const deliveryInDhakas =
      event.target.deliveryInDhaka.value || deliveryInDhaka;
    const outDhakas = event.target.outDhaka.value || outDhaka;
    const colors = event.target.color.value || color;
    const sizes = event.target.size.value || size;
    const videos = event.target.video.value || video;
    const brands = event.target.brand.value || brand;
    let sPrices = sP === "0" ? sP : parseInt(sP) + parseInt(sP) * 0.1 || sPrice;
    const productShortD = shoptDUpdate;
    const PromoSelected = promoSelected;

    let formData = new FormData();
    formData.append("productNames", productNames);
    formData.append("orderTypes", orderTypes);
    formData.append("prices", prices);
    formData.append("sPrices", sPrices);
    formData.append("quantitys", quantitys);
    formData.append("deliveryInDhakas", deliveryInDhakas);
    formData.append("outDhakas", outDhakas);
    formData.append("colors", colors);
    formData.append("sizes", sizes);
    formData.append("videos", videos);
    formData.append("brands", brands);
    formData.append("productShortD", productShortD);
    formData.append("ProductlongDescription", longD);
    formData.append("Sku", Sku);
    formData.append("PromoSelected", JSON.stringify(PromoSelected));

    // console.log(ProductlongDescription)
    fetch(`https://api.shopinshop.com.bd/product/update/${productModal._id}`, {
      method: "PATCH",
      headers: {
        // 'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.status !== 200) {
          setUpdateLoading(false);
          toast("Somethings is wrong. Please Try again.");
        } else {
          // console.log(res);
          return res.json();
        }
      })
      .then((data) => {
        if (data.acknowledged && data.modifiedCount > 0) {
          toast("Product Update Successfully.");
          setProductModal(null);
          setUpdateLoading(false);
        } else if (data.modifiedCount === 0) {
          toast("No Product Update");
          setUpdateLoading(false);
        }
      });
  };

  return (
    <div>
      <input type="checkbox" id="product-edit-modal" className="modal-toggle" />
      <div className="modal z-[999999999999999999]">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-lg text-center text-orange-500">
            Product Update
          </h3>

          <form onSubmit={handelProductUpdate}>
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text font-semibold">Product Name</span>
              </label>
              <input
                type="text"
                placeholder={productName}
                defaultValue={pName}
                onChange={(e) => setPname(e.target.value)}
                name="productName"
                className="input input-bordered w-full"
              />
            </div>

            <div className="form-control w-full ">
              <label className="label">
                <span className="label-text font-semibold">Product SKU</span>
              </label>
              <input
                type="text"
                defaultValue={Sku}
                onChange={(e) => setSku(e.target.value)}
                placeholder="Product SKU"
                className="input input-bordered input-primary w-full"
              />
            </div>

            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text font-semibold">
                  Product Price (
                  <span className="text-orange-500">including 10% VAT</span>)
                </span>
              </label>
              <input
                type="number"
                placeholder={price}
                name="price"
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text font-semibold">
                  Product Special Price{" "}
                  <span className="text-orange-500 text-sm">
                    (Enter 0 if you don't want to pay Special Price)
                  </span>
                  {sPrice && (
                    <span className="text-orange-500">including 10% VAT</span>
                  )}{" "}
                </span>
              </label>
              <input
                type="text"
                placeholder={
                  sPrice ||
                  "Special Price is now closed. If you want to open then give the price"
                }
                name="sPrice"
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text font-semibold">
                  Product quantity
                </span>
              </label>
              <input
                type="text"
                placeholder={quantity}
                name="quantity"
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control w-full mb-4">
              <label className="label">
                <p className="label-text font-semibold">
                  Product color{" "}
                  <span className="text-orange-500 text-xs">
                    If you want to give more than one color, you must give a
                    comma(,){" "}
                  </span>
                </p>
              </label>
              <input
                type="text"
                placeholder={color}
                name="color"
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control w-full mb-4">
              <label className="label">
                <p className="label-text font-semibold">
                  Product Size{" "}
                  <span className="text-orange-500 text-xs">
                    If you want to give more than one size, you must give a
                    comma(,){" "}
                  </span>
                </p>
              </label>
              <input
                type="text"
                placeholder={size}
                name="size"
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control w-full mb-4">
              <label className="label">
                <p className="label-text font-semibold">
                  Delivery Charge in Dhaka
                </p>
              </label>
              <input
                type="text"
                placeholder={deliveryInDhaka}
                name="deliveryInDhaka"
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control w-full mb-4">
              <label className="label">
                <p className="label-text font-semibold">
                  Delivery Charge out of Dhaka
                </p>
              </label>
              <input
                type="text"
                placeholder={outDhaka}
                name="outDhaka"
                className="input input-bordered w-full"
              />
            </div>

            <div className="form-control w-full ">
              <label className="label">
                <span className="label-text font-semibold">Order Type</span>
              </label>

              <select className="select select-bordered" name="orderType">
                <option value="" disabled selected>
                  {orderType}
                </option>
                <option>COD</option>
                <option>Pay</option>
                <option>Pre-Order</option>
              </select>
            </div>

            <div className="form-control w-full ">
              <label className="label">
                <span className="label-text font-semibold">Product Brand</span>
              </label>
              <input
                type="text"
                name="brand"
                placeholder={brand || "N/A"}
                className="input input-bordered input-primary w-full"
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text font-semibold">
                  Product Short Description
                </span>
              </label>
              <textarea
                name="shortDescription"
                defaultValue={shoptDUpdate}
                onChange={(e) => setShortDUpdate(e.target.value)}
                className="textarea textarea-bordered textarea-primary  h-24"
                placeholder="Product Short Description"
              />
            </div>

            <div className="z-[-1]">
              <label className="label">
                <span className="label-text font-semibold">
                  Product Long Description
                </span>
              </label>
              <LongDescription
                text={longD}
                settext={setLongD}
              ></LongDescription>
            </div>

            <div className="z-[-1]">
              <label className="label">
                <span className="label-text font-semibold">
                  Product Long Description
                </span>
              </label>
              <ProductPromoUpdate
                promoSelected={promoSelected}
                setPromoSelected={setPromoSelected}
              ></ProductPromoUpdate>
            </div>

            <div className="form-control w-full mb-4">
              <label className="label">
                <p className="label-text font-semibold">
                  Product Youtube Video ID
                </p>
              </label>
              <input
                type="text"
                placeholder={video || "N/A"}
                name="video"
                className="input input-bordered w-full"
              />
            </div>

            <div className="flex justify-end">
              <div className="modal-action mr-4">
                {updateLoading ? (
                  <ButtonSpinner></ButtonSpinner>
                ) : (
                  <button className="btn bg-orange-500">Update</button>
                )}
              </div>
              <div className="modal-action">
                <button
                  type="reset"
                  onClick={() => setProductModal(null)}
                  className="btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductEditModal;

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useAdmin from "../../Hooks/useAdmin";
import Pagination from "../../Shared/Pagination/Pagination";
import Spinner from "../../Shared/Spinner/Spinner";
import auth from "../../firebase.init";
import CheckBox from "./CheckBox";
import ProductCategoryEditModal from "./ProductCategoryEditModal";
import ProductDeleteModal from "./ProductDeleteModal";
import ProductEditModal from "./ProductEditModal";
import ProductImageUpdateModal from "./ProductImageUpdateModal";
import ProductViewModal from "./ProductViewModal";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [user, loading] = useAuthState(auth);
  const [productModal, setProductModal] = useState(null);
  const [productLoad, setProductLoad] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdmin] = useAdmin(user);
  const [productStatis, setProductStatus] = useState("");
  const [products, setProducts] = useState([]);
  const [productEditModal,setProductEditModal]=useState(false);
  const [productDeleteModals,setProductDeleteModal]=useState(false);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [pendingStatus, setPendingStatus] = useState([]);

  useEffect(() => {
    if (user && currentPage) {
      fetch(
        `https://api.shopinshop.com.bd/product/${user?.email}?name=${
          search.toLocaleLowerCase() || ""
        }&page=${currentPage - 1}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setProducts(data);
          setProductLoad(false);
        });
    }
  }, [user, currentPage, search, productModal, productStatis, isCheck]);

  useEffect(() => {
    fetch(
      `https://api.shopinshop.com.bd/products/dashboard/count/${user?.email}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(parseInt(count) / 50);
        setPageCount(pages);
        setTotalItem(count);
      });
  }, []);

  useEffect(() => {
    fetch(`https://api.shopinshop.com.bd/product/status/apdate`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPendingStatus(data);
      });
  }, [isCheckAll]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(pendingStatus.map((li) => li.status === "Pending" && li._id));
    if (isCheckAll === true) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const handelMmultiUpdate = () => {
    fetch("https://api.shopinshop.com.bd/product/status/apdate", {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({ isCheck }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged && data.modifiedCount > 0) {
          toast("All the products you selected have been accepted");
          setIsCheck([]);
        } else {
          toast("Something went wrong. Please try again");
        }
      });
  };

  const productUpdateStatus = (id, value) => {
    fetch(`https://api.shopinshop.com.bd/product/status/update/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({ value }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast("Product Status Update Successfully");
        } else {
          toast("Something went wrong. Please try again");
        }
      });
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-center md:text-left mb-4 text-xl font-bold">
        Products
      </h1>

      <div className="md:grid grid-cols-2 gap-4  mt-5 items-center ">
        <div className="md:flex justify-between hidden  order-1 md:order-2 gap-4 mb-8 md:mb-0">
          <Link to="/addProduct">
            {" "}
            <button className="bg-green-400 hover:bg-green-500 h-12 w-52 text-white font-bold rounded-full ">
              Add Product
            </button>
          </Link>
        </div>

        <div className="relative bg-white p-4 w-full order-2 md:order-1 rounded-full">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="outline-0 p-2 h-12 rounded-full pl-10 text-orange-500 text-lg border-2  hover:shadow-lg w-full"
            type="text"
            name="search"
            placeholder="Search Product Name/SKU"
          />
          <div className="absolute right-10 top-[35%] cursor-pointer">
            <i className="text-green-500 fa-solid fa-magnifying-glass"></i>
          </div>
        </div>
      </div>

      {isCheck.length > 0 && (
        <div>
          <button onClick={() => handelMmultiUpdate()} className="btn btn-sm">
            Accept All
          </button>
        </div>
      )}

      {loading || productLoad ? (
        <Spinner></Spinner>
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-10">
          <table className="w-full  text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-[#F4F5F7] dark:bg-gray-700 dark:text-gray-400">
              <tr className="text-center text-[10px]">
                <th className="pl-2">
                  <label>
                    <CheckBox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  </label>
                </th>

                <th scope="col" className="px-1 py-3">
                  SKU
                </th>
                <th scope="col" className="px-1 py-3">
                  PRODUCT NAME
                </th>
                <th scope="col" className="px-1 py-3">
                  CATEGORY
                </th>
                <th scope="col" className="px-1 py-3">
                  PRICE
                </th>
                <th scope="col" className="px-1 py-3">
                  Special Price
                </th>
                <th scope="col" className="px-1 py-3">
                  STOCK
                </th>
                <th scope="col" className="px-1 py-3">
                  STATUS
                </th>

                <th scope="col" className="px-1 py-3">
                  DETAILS
                </th>

                <th scope="col" className="px-1 py-3">
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {products?.map((product) => (
                <tr
                  key={product._id}
                  className="bg-white border-b text-[12px] dark:bg-gray-800 dark:border-gray-700"
                >
                  <th className="pl-2">
                    <label>
                      <CheckBox
                        key={product._id}
                        type="checkbox"
                        name={product.productName}
                        id={product._id}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(product._id)}
                      />
                    </label>
                  </th>
                  <th
                    scope="row"
                    className="py-4 px-1 text-[10px] dark:text-white whitespace-nowrap"
                  >
                    {product.sku}
                  </th>
                  <td className="px-2">
                    <div className="flex items-center  justify-between">
                      <label
                        htmlFor="image-update-modal"
                        className="cursor-pointer tooltip"
                        data-tip={product?.uid ? "Amazon Product" : "Update Image?"}
                      >
                        <img
                          onClick={() =>
                            !product?.image && setProductModal(product)
                          }
                          src={
                            product?.image
                              ? product?.image
                              : `https://api.shopinshop.com.bd/${product.primaryImage}`
                          }
                          width="40"
                          alt="product images"
                        />
                      </label>
                      <p
                        className="w-40 ml-4  tooltip "
                        data-tip={product.productName}
                      >
                        {product.productName.length > 15
                          ? product.productName.slice(0, 15) + "..."
                          : product.productName}
                      </p>
                    </div>
                  </td>
                  <td
                    className="px-2 py-4 tooltip capitalize"
                    data-tip="Update Category?"
                  >
                    <label
                      onClick={() => setProductModal(product)}
                      htmlFor="category-edit-modal"
                      className=" cursor-pointer"
                    >
                      {" "}
                      {Array.isArray(product.category) &&
                        product.mainCategoryID.map((x) => x.label).join(" , ")}
                    </label>
                  </td>
                  <td className="px-2 py-4">{product.price} &#x09F3;</td>
                  <td className="px-2 py-4">
                    {product.sPrice || "N/A"} &#x09F3;
                  </td>
                  <td className="px-2 py-4">{product.quantity}</td>
                  <td className="px-2 py-4">
                    {isAdmin ? (
                      <select
                        onChange={(e) => {
                          setProductStatus(e.target.value);
                          productUpdateStatus(product._id, e.target.value);
                        }}
                        className="outline-0 cursor-pointer border-2 hover:shadow-lg text-slate-400 p-1 rounded-full px-4"
                        id="cars"
                      >
                        <option
                          className="text-orange-600"
                          defaultValue={product.status}
                          selected
                          disabled
                        >
                          {product.status}
                        </option>
                        <option>Pending</option>
                        <option>Accept</option>
                        <option>Mute</option>
                        <option>Block</option>
                      </select>
                    ) : (
                      <p className="bg-orange-200 p-1 rounded-full text-center text-blue-500 capitalize">
                        {product.status}
                      </p>
                    )}
                  </td>
                  <td className="px-2 py-4">
                    {product?.uid ? (
                      <Link to={`https://www.amazon.in/sis/dp/${product?.uid}`}  target="_blank" rel="noopener noreferrer">
                       <p className="bg-[#4ADE80] text-white rounded-md cursor-pointer">Amazon</p>
                      </Link>
                    ) : (
                      <label htmlFor="product-view-modal">
                        <i
                          onClick={() => setProductModal(product)}
                          className="cursor-pointer fa-solid fa-eye"
                        ></i>
                      </label>
                    )}
                  </td>
                  <td className="px-4 py-4">
                    <div className="flex justify-between gap-x-4">
                      <label htmlFor="product-edit-modal">
                        {" "}
                        <i
                          onClick={() => setProductEditModal(product)}
                          className=" cursor-pointer fa-solid fa-pen-to-square"
                        ></i>
                      </label>

                      <label htmlFor="product-delete-modal">
                        <i
                          onClick={() => setProductDeleteModal(product)}
                          className="cursor-pointer fa-solid fa-trash-can"
                        ></i>
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {totalItem > 50 && (
        <div className="flex justify-center md:justify-end">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
          ></Pagination>
        </div>
      )}

      <Link to="/addProduct">
        <div className="h-12 w-12 bg-white flex justify-center items-center cursor-pointer fixed bottom-20 md:hidden  right-5 shadow-lg rounded-full">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>

          <i className="text-blue-600  text-lg fa-solid fa-plus"></i>
        </div>
      </Link>

      {productDeleteModals && (
        <ProductDeleteModal
          products={products}
          setProducts={setProducts}
          setProductModal={setProductDeleteModal}
          productModal={productDeleteModals}
        ></ProductDeleteModal>
      )}
      {productModal && (
        <ProductViewModal productModal={productModal}></ProductViewModal>
      )}
      {productEditModal && (
        <ProductEditModal
          productModal={productEditModal}
          setProductModal={setProductEditModal}
        ></ProductEditModal>
      )}
      {productModal && (
        <ProductImageUpdateModal
          productModal={productModal}
          setProductModal={setProductModal}
        ></ProductImageUpdateModal>
      )}
      {productModal && (
        <ProductCategoryEditModal
          productModal={productModal}
          setProductModal={setProductModal}
        ></ProductCategoryEditModal>
      )}
    </div>
  );
};

export default Products;

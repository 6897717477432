import React from 'react';

const CheckBox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <input

            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
        />
    );
};

export default CheckBox;
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ButtonSpinner from '../../Shared/Spinner/ButtonSpinner';

const CategoryImageUpdate = ({ refetch, categoryModal, setCategoryModal, subCategory }) => {
    const [imgLoading, setImageLoading] = useState(null);
    const imgStore_key = '2f6c6879a39132782b251889cb5d783f';
    // console.log(categoryModal)
    const [buttonLoading, setButtonLoadding] = useState(false);

    const handelCtegoryImageUpdate = (event) => {
        event.preventDefault();
        const img = event?.target?.img?.files[0];

        if (img) {
            setButtonLoadding(true);
            const formData = new FormData();
            formData.append('image', img);
            const url = `https://api.imgbb.com/1/upload?key=${imgStore_key}`;
            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(result => {
                    if (result.success) {
                        const image = result.data.url;
                        const slug = subCategory.slug;
                        // console.log(image)
                        fetch(`https://api.shopinshop.com.bd/category/image/update/${categoryModal._id}`, {
                            method: "PUT",
                            headers: {
                                'content-type': 'application/json',
                                authorization: `Bearer ${localStorage.getItem('accessToken')}`
                            },
                            body: JSON.stringify({ image, slug })
                        })
                            .then(res => res.json())
                            .then(data => {
                                if (data.acknowledged) {
                                    toast("Successful Update Image");
                                    event.target.reset();
                                    refetch();
                                    setCategoryModal(null);
                                    setButtonLoadding(false);

                                } else {
                                    toast.error("Some Problem Occurs! Please Reload Browser");
                                    setButtonLoadding(false);
                                }
                            });

                    }
                })
        }
    }


    // console.log(subCategory.slug)
    return (
        <div>

            <input type="checkbox" id="catrgory-image-update" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-center text-orange-500">Update Category Image</h3>
                    <form onSubmit={handelCtegoryImageUpdate}>
                        <div>
                            <label className="flex items-center ">
                                <span className="sr-only">Choose File</span>
                                <input onChange={(e) => setImageLoading(e.target.value)} type="file" name='img' defaultValue=''
                                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"

                                />
                            </label>
                        </div>


                        <div className="modal-action">
                            {
                                imgLoading && !buttonLoading ? <button className='btn bg-orange-500'>Update</button> : buttonLoading ? <ButtonSpinner></ButtonSpinner> : <button className='btn bg-orange-500' disabled>Update</button>
                            }

                            <label onClick={() => {
                                setCategoryModal(null);
                                refetch();
                            }} htmlFor="catrgory-image-update" className="btn">Cancel</label>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    );
};

export default CategoryImageUpdate;
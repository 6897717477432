import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ButtonSpinner from '../../Shared/Spinner/ButtonSpinner';

const PromoDelete = ({ setPromosData, promosData, refetch }) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const handelPromoDelete = (id) => {
        // console.log(id);
        setDeleteLoading(true);
        fetch(`https://api.shopinshop.com.bd/admin/delete/promo/${id}`, {
            method: "DELETE",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged && data.deletedCount > 0) {
                    toast("Your promo has been deleted");
                    setPromosData(null);
                    refetch();
                    setDeleteLoading(false);

                }
                else {
                    toast("Sorry there was a problem");
                    setDeleteLoading(false);

                }
                // console.log(data)
            })

    }
    return (
        <div>

            <input type="checkbox" id="promo-delete-modal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-center text-orange-500">Are you sure you want to delete the Promo?</h3>

                    <div className='flex justify-end'>
                        <div className="modal-action mr-4">
                            {
                                deleteLoading ? <ButtonSpinner></ButtonSpinner> : < button onClick={() => handelPromoDelete(promosData._id)} className="btn bg-orange-500">Delete</button>
                            }

                        </div>
                        <div className="modal-action">
                            <label onClick={() => setPromosData(null)} htmlFor="promo-delete-modal" className="btn">Cancel</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromoDelete;
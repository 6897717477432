import React, { useState } from "react";
import { toast } from "react-toastify";
import ChildCategoryBannerUpload from "./ChildCategoryBannerUpload";

const CategoryEditModal = ({
  subCategory: categoryModal,
  setCategoryModal,
  refetch,
  categoryModalMain,
}) => {
  const [childBanner, setChildBanner] = useState(null);
  // console.log(categoryModalMain._id)
  const handelCategoryUpdate = event => {
    event.preventDefault();

    const subCategorys = [];
    if (!event.target.subCategory.length) {
      subCategorys.push({
        name:
          event.target.subCategory.value || categoryModal.subCategory[0].name,
        slug:
          event.target.subCategory.value.replace(/\s/g, "-") ||
          categoryModal.subCategory[0].slug,
        banner: categoryModal.subCategory[0].banner || "",
      });
    } else {
      for (let i = 0; i < categoryModal.subCategory.length; i++) {
        const subCategoryName = event.target?.subCategory[i].value;
        subCategorys.push({
          name: subCategoryName || categoryModal.subCategory[i].name,
          slug:
            subCategoryName.replace(/\s/g, "-") ||
            categoryModal.subCategory[i].slug,
          banner: categoryModal.subCategory[i].banner || "",
        });
      }
    }

    const subCategory = subCategorys;
    const name = event.target.name.value || categoryModal.name;
    const slug = name.replace(/\s/g, "-");
    const status = categoryModal.status;
    const img = categoryModal.img;
    const banner = categoryModal.banner || "";

    const updateAllCategory = {
      name,
      banner,
      slug,
      status,
      img,
      subCategory,
    };

    fetch(
      `https://api.shopinshop.com.bd/category/update/all/${categoryModal.slug}?mainId=${categoryModalMain._id}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(updateAllCategory),
      }
    )
      .then(res => res.json())
      .then(data => {
        if (data.acknowledged && data.modifiedCount > 0) {
          toast("Category Update Successfully");
          refetch();
          setCategoryModal(null);
        }
      });
  };

  const handelDeleteSubCategory = slug => {
    fetch(`https://api.shopinshop.com.bd/subCategory/delete/${categoryModal.slug}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({ slug }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.acknowledged && data.modifiedCount > 0) {
          toast("Sub-Category Delete Successfully");
          refetch();
          setCategoryModal(null);
        }
      });
  };

  return (
    <div>
      <input
        type="checkbox"
        id="update-modal-category"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-center text-orange-500">
            Update Category
          </h3>

          <form onSubmit={handelCategoryUpdate}>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-xl font-semibold">
                  Category
                </span>
              </label>
              <input
                type="text"
                defaultValue={categoryModal.name}
                name="name"
                placeholder={categoryModal.name}
                className="input input-primary w-full"
              />
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-xl font-semibold">
                  Sub Category
                </span>
              </label>
              {categoryModal?.subCategory.map((sub, index) => (
                <div key={index} className="flex gap-4 items-center">
                  <label
                    onClick={() =>
                      setChildBanner({
                        allCategory: categoryModal,
                        childCategory: sub,
                      })
                    }
                    htmlFor="child-banner-update"
                  >
                    {" "}
                    <i
                      title="Banner Upload and Update"
                      className={
                        sub.banner
                          ? "cursor-pointer text-orange-400 fa fa-upload"
                          : "cursor-pointer hover:text-orange-400 fa fa-upload"
                      }
                      aria-hidden="true"
                    ></i>
                  </label>
                  <input
                    key={index}
                    type="text"
                    defaultValue={sub.name}
                    name={`subCategory`}
                    placeholder={sub.name}
                    className="input input-primary w-full mb-2"
                  />
                  <i
                    onClick={() => handelDeleteSubCategory(sub.slug)}
                    className="cursor-pointer hover:text-orange-400 far fa-trash-alt"
                  ></i>
                </div>
              ))}
            </div>

            <div className="flex justify-end">
              <div className="modal-action mr-4">
                <button type="submit" className="btn bg-orange-500">
                  Update Category
                </button>
              </div>
              <div className="modal-action">
                <button
                  type="reset"
                  onClick={() => setCategoryModal(null)}
                  className="btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {childBanner && (
        <ChildCategoryBannerUpload
          refetch={refetch}
          childBanner={childBanner}
          setChildBanner={setChildBanner}
          categoryModalMain={categoryModalMain._id}
        ></ChildCategoryBannerUpload>
      )}
    </div>
  );
};

export default CategoryEditModal;

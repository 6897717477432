import { useEffect, useState } from "react";


const useAdminOrEditor = (user) => {

    const [adminEditor, setAdminEditor] = useState(false);
    const [adminEditorLoading, setAdminEditorLoading] = useState(true);
    useEffect(() => {
        const email = user?.email;
        if (email) {
            fetch(`https://api.shopinshop.com.bd/adminUser/${email}`, {
                method: "GET",
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then(res => res.json())
                .then(data => {
                    // console.log('admin data', data)
                    if (data.admin === true || data.admin === "editor") {
                        setAdminEditor(data.admin);
                        setAdminEditorLoading(false);
                    } else {
                        setAdminEditorLoading(true);
                    }

                })
        }

    }, [user]);

    return [adminEditor, adminEditorLoading];
};

export default useAdminOrEditor;
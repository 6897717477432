import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ButtonSpinner from '../../Shared/Spinner/ButtonSpinner';

const EditePromoModal = ({ refetch, promosData, setPromosData }) => {
    const [editLoading, setEditeLoading] = useState(false);
    const imgStore_key = '2f6c6879a39132782b251889cb5d783f';
    // console.log(promosData)

    const handelEdite = (event) => {
        event.preventDefault();
        setEditeLoading(true);
        const title = event.target.title.value || promosData.label;
        const img = event?.target?.img?.files[0];

        if (img) {
            const formData = new FormData();
            formData.append('image', img);
            const url = `https://api.imgbb.com/1/upload?key=${imgStore_key}`;
            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(result => {
                    if (result.success) {
                        const newImg = result.data.url;
                        const updatePromo = {
                            title,
                            newImg
                        }

                        fetch(`https://api.shopinshop.com.bd/admin/promo/all/update/${promosData._id}`, {
                            method: "PUT",
                            headers: {
                                'content-type': 'application/json',
                                authorization: `Bearer ${localStorage.getItem('accessToken')}`
                            },
                            body: JSON.stringify(updatePromo),
                        })
                            .then(res => res.json())
                            .then(data => {
                                if (data.acknowledged && data.modifiedCount > 0) {
                                    toast("Promo Update successfully");
                                    setPromosData(null);
                                    refetch();
                                    setEditeLoading(false);
                                } else {
                                    toast("Something is wrong.Please try Again");
                                    setEditeLoading(false);
                                }
                                // console.log(data)
                            })
                    }
                })


        } else {
            const newImg = promosData.value;
            const updatePromo = {
                title,
                newImg
            }
            fetch(`https://api.shopinshop.com.bd/admin/promo/all/update/${promosData._id}`, {
                method: "PUT",
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(updatePromo),
            })
                .then(res => res.json())
                .then(data => {
                    if (data.acknowledged && data.modifiedCount > 0) {
                        toast("Promo Update successfully");
                        setPromosData(null);
                        refetch();
                        setEditeLoading(false);

                    } else {
                        toast("Something is wrong.Please try Again");
                        setEditeLoading(false);
                    }
                    // console.log(data)
                })
        }
        // console.log(title, img)
    }
    return (
        <div>



            <input type="checkbox" id="edite-modal-promo" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-center text-orange-500">Update Promo</h3>

                    <div>
                        <form onSubmit={handelEdite}>

                            <div className='mt-4'>
                                <img src={promosData.value} height='60px' width='60px' alt='Promo Images' />
                            </div>
                            <div>
                                <div className='flex justify-between gap-4'>
                                    <div className='my-6'>
                                        <span className="pb-4 label-text w-full text-warning font-semibold capitalize">(Please try to square the promo image)</span>
                                        <label className="block">
                                            <span className="sr-only">Choose File</span>
                                            <input type="file" name='img' className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" />
                                        </label>
                                    </div>

                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-primary font-semibold capitalize">Promo Name</span>
                                    </label>
                                    <input type="text" name='title' defaultValue={promosData.label} placeholder="Promo Name" className="input input-warning w-full" />
                                </div>

                            </div>















                            <div className="modal-action">
                                {
                                    editLoading ? <ButtonSpinner></ButtonSpinner> : <button className='btn bg-orange-500'>Update</button>
                                }

                                <label onClick={() => setPromosData(null)} htmlFor="edite-modal-promo" className="btn">Cancel</label>
                            </div>
                        </form>

                    </div>



                </div>
            </div>
        </div>
    );
};

export default EditePromoModal;
import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';

const ProductPromoUpdate = ({ promoSelected, setPromoSelected }) => {
    const [promodata, setPromodata] = useState([]);




    useEffect(() => {
        fetch('https://api.shopinshop.com.bd/cliend/get/promoData', {
            method: "GET",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                setPromodata(data);
            })
    }, [])

    return (
        <div className='my-2'>
            <h1 className='label-text font-semibold mb-2'>Product Promo <span className='text-sm text-primary'>(Make a maximum of 5 options)</span></h1>
            <MultiSelect
                className="select-primary border-primary border-[1px] rounded-lg w-full"
                options={promodata}
                value={promoSelected}
                onChange={setPromoSelected}
                labelledBy="Select Promo"
            />
            {/* {
                promo.length <= 0 && <span className="label-text-alt text-warning">
                    <i className="fa-solid fa-circle-exclamation"></i> Please select promo for your product.
                </span>
            } */}
        </div>
    );
};

export default ProductPromoUpdate;
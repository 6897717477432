import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import Spinner from '../../Shared/Spinner/Spinner';
import auth from '../../firebase.init';
import useAdminOrEditor from '../useAdminOrEditor';


const RequireAdminOrEditor = ({ children }) => {

    const [user, loading] = useAuthState(auth);
    // console.log(user)
    const [adminEditor, adminEditorLoading] = useAdminOrEditor(user);


    if (loading || adminEditorLoading) {
        return <Spinner></Spinner>
    }

    if (!adminEditor) {
        return <Navigate to="/" />;
    }

    return children;


};

export default RequireAdminOrEditor;
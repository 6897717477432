import { useEffect, useState } from "react";


const useEditor = (user) => {

    const [editor, setEditor] = useState(false);
    const [editorLoading, setEditorLoading] = useState(true);
    useEffect(() => {
        const email = user?.email;
        if (email) {
            fetch(`https://api.shopinshop.com.bd/adminUser/${email}`, {
                method: "GET",
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then(res => res.json())
                .then(data => {
                    // console.log("Editor", data)
                    setEditor(data);
                    setEditorLoading(false);
                })
        }

    }, [user]);

    return [editor, editorLoading];
};

export default useEditor;
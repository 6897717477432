import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import useAdmin from '../../Hooks/useAdmin';
import Pagination from '../../Shared/Pagination/Pagination';
import Spinner from '../../Shared/Spinner/Spinner';
import auth from '../../firebase.init';

const SearchProductForOrder = ({ selectProducts, setSelectProducts }) => {
    const [orderLoad, setOrderLoad] = useState(false);
    const [user, loading] = useAuthState(auth);
    const [admin, adminLoading] = useAdmin(user);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [productStatis, setProductStatus] = useState('');
    const [isCheck, setIsCheck] = useState([]);
    const [totalItem, setTotalItem] = useState(0)
    const [productModal, setProductModal] = useState(null);
    const [productsData, setProductsData] = useState([]);

    const [productLoad, setProductLoad] = useState(true);
    //search and select product
    useEffect(() => {
        if (user && currentPage) {
            fetch(`https://api.shopinshop.com.bd/product/${user?.email}?name=${search.toLocaleLowerCase() || ''}&page=${currentPage - 1}`, {
                method: "GET",
                headers: {
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
            })
                .then(res => res.json())
                .then(data1 => {
                    const data = data1.filter(result => result.sku === search)
                    // console.log(data)
                    setProductsData(data);
                    setProductLoad(false);

                })
        }
    }, [user, currentPage, search, productModal, productStatis, isCheck]);
    return (
        <div>
            <div className='md:grid grid-cols-1 gap-4  mt-5 items-center '>


                <div className='relative bg-white p-4 w-full order-2 md:order-1 rounded-full'>
                    <input onChange={(e) => setSearch(e.target.value)} className='outline-0 p-2 h-12 rounded-full pl-10 text-orange-500 text-lg border-2  hover:shadow-lg w-full' type="text" name="search" placeholder='Search Product SKU' />
                    <div className='absolute right-10 top-[35%] cursor-pointer'>
                        <i className="text-green-500 fa-solid fa-magnifying-glass"></i>
                    </div>
                </div>

            </div>
            {
                loading || productLoad ? <Spinner></Spinner>
                    :
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-10">
                        <table className="w-full  text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-[#F4F5F7] dark:bg-gray-700 dark:text-gray-400">
                                <tr className='text-center text-[10px]'>
                                    <th scope="col" className="px-1 py-3">
                                        SKU
                                    </th>
                                    <th scope="col" className="px-1 py-3">
                                        PRODUCT NAME
                                    </th>
                                    <th scope="col" className="px-1 py-3">
                                        PRICE
                                    </th>
                                    <th scope="col" className="px-1 py-3">
                                        Special Price
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    productsData?.map(product =>

                                        <tr key={product._id}
                                            onClick={() => setSelectProducts(product)}
                                            className="bg-white border-b text-[12px] dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">

                                            <th scope="row" className="py-4 px-1 text-[10px] dark:text-white whitespace-nowrap">
                                                {product.sku}
                                            </th>
                                            <td className="px-2">
                                                <div className='flex items-center  justify-between'  >
                                                    <label htmlFor="image-update-modal" className="cursor-pointer tooltip"><img
                                                        src={`https://api.shopinshop.com.bd/${product.primaryImage}`} width='40' alt="product images" /></label>

                                                    <p className='w-40 ml-4  tooltip ' data-tip={product.productName}>{product.productName.length > 10 ? product.productName.slice(0, 10) + "..." : product.productName}</p>
                                                </div>
                                            </td>

                                            <td className="px-2 py-4">
                                                {product.price} &#x09F3;
                                            </td>
                                            <td className="px-2 py-4">
                                                {product.sPrice || "N/A"} &#x09F3;
                                            </td>

                                        </tr >

                                    )
                                }

                            </tbody >
                        </table >

                    </div >
            }
            {
                totalItem > 50 && <div className='flex justify-center md:justify-end'>
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount}></Pagination>
                </div>
            }
        </div >
    );
};

export default SearchProductForOrder;
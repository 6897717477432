import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ButtonSpinner from '../../Shared/Spinner/ButtonSpinner';

const PromosModal = ({ promo, refetch, setPromo }) => {
    const [uploadLoding, setUploading] = useState(false);
    const imgStore_key = '2f6c6879a39132782b251889cb5d783f';
    const handelPostPromo = (event) => {
        event.preventDefault();
        setUploading(true);
        const img = event?.target?.img?.files[0];
        const label = (event.target.title.value).trim();
        const status = event.target.check.checked;


        if (img) {
            const formData = new FormData();
            formData.append('image', img);
            const url = `https://api.imgbb.com/1/upload?key=${imgStore_key}`;
            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(result => {
                    if (result.success) {
                        const value = result.data.url;
                        const promo = {
                            value,
                            label,
                            status
                        }

                        fetch('https://api.shopinshop.com.bd/admin/upload/promo', {
                            method: "POST",
                            headers: {
                                'content-type': 'application/json',
                                authorization: `Bearer ${localStorage.getItem('accessToken')}`
                            },
                            body: JSON.stringify(promo),

                        })
                            .then(res => res.json())
                            .then(data => {
                                if (data.acknowledged) {
                                    toast("Your promo has been added successfully");
                                    setPromo(null);
                                    setUploading(false);
                                    refetch();
                                }
                                else {
                                    toast("Something is Wrong!");
                                    setUploading(false);
                                }
                            })
                            .catch(err => {
                                // console.log(err);
                                toast("Something is Wrong!");
                                setUploading(false);
                            })
                    }
                    else {
                        toast('Sorry, promos cannot be added at this time');
                        setUploading(false);
                    }
                })
        }
        else {
            toast('Sorry, promos cannot be added at this time');
            setUploading(false);
        }






    }
    return (
        <div>
            <div>
                <input type="checkbox" id="promo-add-modal" className="modal-toggle" />
                <div className="modal modal-bottom sm:modal-middle">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg text-center text-orange-500">Promo Add</h3>

                        <form onSubmit={handelPostPromo}>
                            <div>
                                <div className='flex justify-between gap-4'>
                                    <div className='my-6'>
                                        <span className="pb-4 label-text w-full text-warning font-semibold capitalize">(Please try to square the promo image)</span>
                                        <label className="block">
                                            <span className="sr-only">Choose File</span>
                                            <input type="file" name='img' className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" required />
                                        </label>
                                    </div>


                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-primary font-semibold capitalize">Promo Name</span>
                                    </label>
                                    <input type="text" name='title' placeholder="Promo Name" className="input input-warning w-full" required />
                                </div>

                            </div>


                            <div className="form-control">
                                <label className="label  cursor-pointer">
                                    <span className="label-text text-primary font-semibold">Promo of/on</span>
                                    <input type="checkbox" name='check' className="toggle toggle-primary" />
                                </label>
                            </div>

                            <div className='flex justify-end'>
                                <div className="modal-action mr-4">

                                    {
                                        uploadLoding ? <ButtonSpinner></ButtonSpinner> : <button stype='submit' className="btn bg-orange-500">Upload Promo</button>
                                    }


                                </div>
                                <div className="modal-action">
                                    <label onClick={() => setPromo(null)} htmlFor="promo-add-modal" className="btn">Cancel</label>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromosModal;
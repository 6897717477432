import React from "react";
import { Link } from "react-router-dom";

const OrderModal = ({ setOpenOrderModal, openOrderModal, admin }) => {
  return (
    <div>
      <input type="checkbox" id="my-modal-60" className="modal-toggle" />
      <div
        style={{ zIndex: "99999999999999999999999" }}
        className="modal  modal-bottom "
      >
        <div className="modal-box w-11/12 max-w-5xl">
          <div className="overflow-x-auto w-full">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>SKU</th>
                  <th>Delivery Charge</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {openOrderModal?.products?.map((product, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={product?.uid ? `https://shopinshop.com.bd/singleProduct/sis/${product?.uid}`:`https://shopinshop.com.bd/singleProduct/${product?.productId}`}
                        target="_blank"
                      >
                        <div className="flex items-center space-x-3">
                          <div className="avatar">
                            <div className="mask mask-squircle w-12 h-12">
                              <img
                                src={
                                  product?.uid
                                    ? product?.productImage
                                    : `https://api.shopinshop.com.bd/${product?.productImage}`
                                }
                                alt="Avatar Tailwind CSS Component"
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              title={product?.productName}
                              className="font-bold"
                            >
                              {product?.productName.length > 20
                                ? product?.productName.slice(0, 20) + "..."
                                : product?.productName}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td>{openOrderModal?.orderID}</td>
                    <td>{product?.deliveryCharge} &#2547;</td>
                    <td>{product?.productPrices} &#2547;</td>
                    <th>{product?.quantity}</th>
                    <th>
                      {product?.quantity * product?.productPrices +
                        parseInt(product?.deliveryCharge)}{" "}
                      &#2547; {admin && "+ Vat"}
                    </th>
                    <td>{product?.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="modal-action">
            <label
              onClick={() => setOpenOrderModal(null)}
              htmlFor="my-modal-60"
              className="btn btn-primary"
            >
              Cancel
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;

import { useEffect, useState } from "react";


const useAdmin = (user) => {

    const [admin, setAdmin] = useState(false);
    const [adminLoading, setAdminLoading] = useState(true);
    useEffect(() => {
        const email = user?.email;
        if (email) {
            fetch(`https://api.shopinshop.com.bd/adminUser/${email}`, {
                method: "GET",
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
                .then(res => res.json())
                .then(data => {
                    // console.log('admin data', data)
                    if (data.admin === true) {
                        setAdmin(data.admin);
                        setAdminLoading(false);
                    } else {
                        setAdminLoading(true);
                    }

                })
        }

    }, [user]);

    return [admin, adminLoading];
};

export default useAdmin;
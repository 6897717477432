import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ButtonSpinner from '../../Shared/Spinner/ButtonSpinner';
import auth from '../../firebase.init';
import SearchProductForOrder from './SearchProductForOrder';

const PlaceOrdert = () => {
  const [orderLoad, setOrderLoad] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [selectProducts, setSelectProducts] = useState([]);
  const [productLoad, setProductLoad] = useState(true);
  // const [products, setProducts] = UseSearchProduct([]);
  // console.log('select order: ', selectProducts)
  const { register, formState: { errors }, handleSubmit, reset } = useForm();

  const onSubmitePlace = (data) => {
    setOrderLoad(true)
    // console.log("manual: ", data)


    const customerName = data.name;
    const customerPhone = data.number;
    const customerEmail = data.email;
    const category = selectProducts?.category;
    const Pname = selectProducts?.productName;
    const customerState = data.area;
    const customerCity = data.area;
    const Pprice = selectProducts.sPrice || selectProducts?.price;
    const discountPercentage = data.discount;

    function calculateDiscountedPrice(price, discountPercentage) {
      // Convert discount percentage to decimal
      const discount = discountPercentage / 100;

      // Calculate the discounted price
      const discountedPrice = price - (price * discount);

      // Return the discounted price
      return discountedPrice;
    }

    // Usage example



    const deliveryCharge = data.delivery;
    const quantity = data.quantity;
    const totalProductPrice = parseInt(Pprice) * quantity;
    const discountedPrice = calculateDiscountedPrice(totalProductPrice, discountPercentage);
    const totalPrice = parseInt(discountedPrice) + parseInt(data.delivery); //with delevery charge
    const totalPayable = parseInt(discountedPrice) + parseInt(data.delivery); // with delevery charge
    // console.log(TPprice)
    const customerDetails = data.fullAddress;
    const customerNote = data.important;
    const orderType = data.orderType;
    const orderID = selectProducts.sku + Date.now() || data.sku + Date.now();
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    const time = today.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    const products = [{
      productId: selectProducts?._id,
      productName: Pname,
      productImage: selectProducts?.primaryImage,
      quantity: quantity,
      deliveryCharge: deliveryCharge,
      productPrices: +Pprice,
      productTotalPrice: +totalPrice * quantity,
      orderType: orderType,
      status: "Delivered",
      marchentEmail: user?.email,
      shopPhone: '',
      shopName: user?.displayName
    }];

    const orderInfo = {
      customerName,
      customerEmail,
      customerPhone,
      customerState,
      customerCity,
      customerDetails,
      customerNote,
      orderID,
      color: '',
      size: '',
      deliveryCharge,
      totalPrice,
      totalPayable,
      // duePrice,
      isCoupon: false,
      date,
      time,
      status: "Self",
      vatPrice: 0,
      duePrice: 0,
      isCoupon: 0,
      paymentDone: false,
      orderValue: "Cart",
      products,
    };
    const cashInfo = {
      ...orderInfo,
      paymentDone: false,
      paymentMethod: "Cash On Delivery",
      payment: "Due",
    };

    fetch('https://api.shopinshop.com.bd/order/manualorders', {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(cashInfo),
    })
      .then(res => res.json())
      .then(data => {
        if (data.acknowledged) {
          setOrderLoad(false);
          toast("Your order has been successful");
          // console.log("OrderPlaced: ", data)
          reset()
        }
        else {
          setOrderLoad(false);
          toast("A problem occurred, please try again later");
        }
      })
  }

  return (
    <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 items-top'>
      <div>
        <SearchProductForOrder
          selectProducts={selectProducts}
          setSelectProducts={setSelectProducts}
        ></SearchProductForOrder>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmitePlace)} className="grid lg:grid-cols-2 grid-cols-2 gap-4 items-center">
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Customer Name</span></label>
            <input type="text" placeholder="Customer Name" {...register('name', {
              required: "Customer Name is required"
            })} className="input input-bordered w-full max-w-xs" />
            {errors.name && <p className='text-red-500'>{errors.name?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Contact Number</span></label>
            <input type="number" placeholder="Contact Number" {...register('number', {
              required: "Customer number is required"
            })} className="input input-bordered w-full max-w-xs" />
            {errors.number && <p className='text-red-500'>{errors.number?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Email</span></label>
            <input type="email" placeholder="Email" {...register('email', {

            })} className="input input-bordered w-full max-w-xs" />
            {errors.email && <p className='text-red-500'>{errors.email?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Category</span></label>
            <input type="text" placeholder="Category" {...register('category', {

            })}
              value={selectProducts?.category?.map(cate => cate?.label)}
              className="input input-bordered w-full max-w-xs" />
            {errors.category && <p className='text-red-500'>{errors.category?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Product Name</span></label>
            <input type="text" placeholder="Product Name" {...register('product', {

            })}
              value={selectProducts?.productName}
              onChange={(e) => setSelectProducts({ productName: e.target.value })}
              className="input input-bordered w-full max-w-xs" />
            {errors.product && <p className='text-red-500'>{errors.product?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Product SKU</span></label>
            <input type="text" placeholder="Product SKU" {...register('sku', {
            })}
              value={selectProducts?.sku}
              onChange={(e) => setSelectProducts({ sku: e.target.value })}
              className="input input-bordered w-full max-w-xs" />
            {errors.sku && <p className='text-red-500'>{errors.sku?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Area</span></label>
            <input type="text" placeholder="Area" {...register('area', {
              required: "Area is required"
            })} className="input input-bordered w-full max-w-xs" />
            {errors.area && <p className='text-red-500'>{errors.area?.message}</p>}
          </div>

          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Product Quantity</span></label>
            <input type="number" placeholder="Product quantity" {...register('quantity', {
              required: "Quantity is required"
            })}
              // value={selectProducts?.price}
              // onChange={(e) => setSelectProducts({ price: e.target.value })}
              className="input input-bordered w-full max-w-xs" />
            {errors.quantity && <p className='text-red-500'>{errors.quantity?.message}</p>}
          </div>

          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Product Price</span></label>
            <input type="number" placeholder="Product Price" {...register('price', {

            })}
              value={selectProducts.sPrice || selectProducts?.Pprice}
              onChange={(e) => setSelectProducts({ sPrice: e.target.value })}
              className="input input-bordered w-full max-w-xs" />
            {errors.sPrice && <p className='text-red-500'>{errors.sPrice?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Delivery Charge</span></label>
            <input type="number" placeholder="Delivery Charge" {...register('delivery', {
              required: "Delivery Charge is required"
            })} className="input input-bordered w-full max-w-xs" />
            {errors.delivery && <p className='text-red-500'>{errors.delivery?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Discount</span></label>
            <input type="number" placeholder="Discount" {...register('discount', {
              // required: "Discount is required"
            })} className="input input-bordered w-full max-w-xs" />
            {/* {errors.discount && <p className='text-red-500'>{errors.discount?.message}</p>} */}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Customer Address</span></label>
            <input type="address" placeholder="Customer Address" {...register('fullAddress', {
              required: "Customer Address is required"
            })} className="input input-bordered w-full max-w-xs" />
            {errors.fullAddress && <p className='text-red-500'>{errors.fullAddress?.message}</p>}
          </div>
          <div className="form-control w-full ">
            <label className="label"><span className="label-text">Any Importance</span></label>
            <input type="text" placeholder="Any Importance" {...register('important', {
            })} className="input input-bordered w-full max-w-xs" />
            {errors.important && <p className='text-red-500'>{errors.important?.message}</p>}
          </div>

          <div className="form-control w-full ">
            <label className='label' htmlFor="orderType"><span className="label-text">Order Type</span></label>
            <select id="orderType"  {...register("orderType", {
              required: "Order Type is required"
            })}
              className="input input-bordered w-full max-w-xs"
            >
              <option disabled selected>
                Pick one
              </option>
              <option value="cod">COD</option>
              <option value="pre-order">Pre-Order</option>
              <option value="pay">Pay</option>
            </select>
            {errors.orderType && <p className='text-red-500'>{errors.orderType?.message}</p>}
          </div> <br />
          <div className='mt-8 w-full max-w-xs  mx-auto'>
            {
              orderLoad ? <ButtonSpinner></ButtonSpinner>
                :
                // <button className="btn">Order Save</button>
                <input className='btn w-full mt-3' value='Place Order' type="submit" />
            }
          </div>
        </form>
      </div>
    </div>
  );
};

export default PlaceOrdert;
